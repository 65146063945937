import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import { useScreenshot } from "use-react-screenshot";
import useImportScript from "./hooks/useImportScript";
import Logo from "./images/DoWinLogo.webp";

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;

  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

function App() {
  const ref = useRef(null);
  const [isMakingScreenshot, setIsMakingScreenshot] = useState(false);
  const [image, takeScreenshot] = useScreenshot();

  const clickToolbar = () => {
    const toolbarElement = document.getElementById("toolbar");
    if (toolbarElement) {
      const imgElement = toolbarElement.querySelector("img");
      if (imgElement) {
        imgElement.click();
      } else {
        // If the img element is not found, try again after a delay
        setTimeout(clickToolbar, 1000);
      }
    } else {
      // If the toolbar element is not found, try again after a delay
      setTimeout(clickToolbar, 1000);
    }
  };

  useEffect(() => {
    clickToolbar();
  }, []);
  const downLoadImage = () => {
    setIsMakingScreenshot(true);
    try {
      const asyncFunc = async () => {
        try {
          await new Promise((resolve) => setTimeout(resolve, 30));
          const result = await takeScreenshot(ref.current);
          console.log("image", result);
          const a = document.createElement("a");
          a.href = result;
          a.download = "screenshot.jpg";
          a.click();
          setIsMakingScreenshot(false);
        } catch (e) {
          console.error(e);
        }
      };
      asyncFunc();
    } catch (e) {
      console.error(e);
    }
  };
  const getImage = () => {
    try {
      setIsMakingScreenshot(true);
      const asyncFunc = async () => {
        try {
          setIsMakingScreenshot(true);
          await new Promise((resolve) => setTimeout(resolve, 30));
          const result = await takeScreenshot(ref.current);
          console.log("image", result);
          navigator.clipboard.write([
            new ClipboardItem({
              "image/png": dataURItoBlob(result),
            }),
          ]);
          setIsMakingScreenshot(false);
        } catch (error) {
          console.error(error);
        }
      };
      asyncFunc();
    } catch (e) {
      console.error(e);
    }
  };
  useImportScript("/init.js");
  return (
    <main className="App">
      <section className="App-header">
        <div
          className={`${isMakingScreenshot ? "no-border" : ""} magic-editor`}
        >
          <div
            style={{
              display: "flex",
              color: "black",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{
                width: "140px",
              }}
            />
            <div className="editor-btn ">
              <div
                id="toolbar"
                style={{
                  background: "#7a5aed",
                  padding: "4px 24px",
                  borderRadius: "8px",
                  display: "none",
                }}
              ></div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <button onClick={getImage} className="btn-primary">
                Copiază formulă
              </button>
              <button onClick={downLoadImage} className="btn-primary">
                Downloadează formulă
              </button>
              <a
                href="https://cms.dowin.eu"
                className="btn-primary"
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
                rel="noreferrer"
              >
                Link către CMS
              </a>
              <button className="btn-primary" onClick={() => clickToolbar()}>
                Deschide editor
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "center",
              margin: "48px 0px",
            }}
          >
            <h4 style={{ margin: 0 }}>Rezultat formulă</h4>
            <div
              ref={ref}
              id="htmlEditor"
              contenteditable="true"
              style={isMakingScreenshot ? {} : { border: "2px solid black" }}
            ></div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default App;
